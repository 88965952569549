import React from "react";
import Careers from "./Careers";
import Essentials from "./Essentials";
import Events from "./EventsCard/Events";
import Footer from "./Footer/Footer";
import Hero from "./Hero/Hero";
import Solution from "./Solutions/Solution";
import Join from "./Sponsors/Join";

const Homescreen: React.FC = () => {
  return (
    <div>
      <Hero />
      <Solution />
      <Join />
      <Essentials />
      <Events />
      <Careers />
      <Footer />
    </div>
  );
};

export default Homescreen;
