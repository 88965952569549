import React from "react";
import styled from "styled-components";
import Eventcard from "./Eventcard";
import blog from "../Assets/Blog.jpg";
import team from "../Assets/Team Playbook.png";
import agile from "../Assets/Agile Coach.png";
import hv from "../Assets/High-Velocity.jpg";
import wl from "../Assets/worklife-ondemand.png";

const Events = () => {
  return (
    <Container>
      <Wrapper>
        <First>
          <Eventcard
            Image={blog}
            bcol="#EAE6FF"
            bold="Work Life"
            light="Real-life advice, inspiration, and stories from the working world today."
            link="Read Our blog"
          />
          <Eventcard
            Image={team}
            bcol="#E6FCFF"
            bold="Atlassian Team Playbook"
            light="Solve common team challenges with these group exercises."
            link="Check team health"
          />
          <Eventcard
            Image={agile}
            bcol="#DEEBFF"
            bold="The Agile Coach"
            light="Atlassian's no-nonsense guide to agile development."
            link="Visit our Resources"
          />
        </First>
        <Second>
          <TextWrap>
            <Head>EVENTS</Head>
            <Text>Hear from today’s fearless builders and innovators</Text>
            <Link>Learn more</Link>
          </TextWrap>
          <Picture>
            <Image src={hv} />
          </Picture>
          <Picture>
            <Image src={wl} />
          </Picture>
        </Second>
      </Wrapper>
    </Container>
  );
};

export default Events;

const Container = styled.div`
  height: 130vh;
  width: 100%;
  display: flex;
  align-items: center;
  /* background-color: red; */
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 30px;
`;

const First = styled.div`
  /* background-color: blue; */
  width: 100%;
  height: 45%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Second = styled.div`
  width: 100%;
  height: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  /* background-color: green; */
`;
const Wrapper = styled.div`
  /* height: 95%; */
  width: 85%;
  display: flex;
  align-items: center;
  /* background-color: red; */
  justify-content: space-between;
  flex-direction: column;
`;

const Text = styled.div`
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #5a5656;
`;
const Picture = styled.div`
  height: 400px;
  width: 450px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  /* margin-right: 20px; */
`;
const TextWrap = styled.div`
  height: 400px;
  width: 450px;
  display: flex;
  justify-content: center;
  /* justify-content: space-between; */
  /* align-items: center; */
  flex-direction: column;
`;
const Link = styled.div`
  font-size: 20px;
  font-weight: 450;
  color: #145dcaca;
`;
const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
`;
const Head = styled.div`
  font-weight: bolder;
  font-size: 20px;
  color: #34bfd1;
  margin-bottom: 20px;
`;
