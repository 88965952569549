import React from "react";
import styled from "styled-components";
// import logo from "../Assets/logos-atlassian-logo-gradient-horizontal-neutral.svg";
import { TfiWorld } from "react-icons/tfi";
import { MdArrowDropDown } from "react-icons/md";
import { BiCopyright } from "react-icons/bi";
// import fb from "../Assets/facebook-badge.svg";
// import tw from "../Assets/twitter.svg";
// import linkd from "../Assets/linked-in.svg";
// import yt from "../Assets/youtube.svg";

const Footer = () => {
  return (
    <Container>
      <Wrapper>
        <Logodiv>{/* <Logo src={logo} /> */}</Logodiv>
        <Navs>
          <One>Products</One>
          <Two>Jira Software</Two>
          <Two>Jira Align</Two>
          <Two>Jira Service Mangement</Two>
          <Two>Confluence</Two>
          <Two>Trello</Two>
          <Two>Bitbucket</Two>
          <Link>View all products</Link>
        </Navs>
        <Navs>
          <One>Resources</One>
          <Two>Technical Support</Two>
          <Two>Purchasing & licensing</Two>
          <Two>Atlassian Community</Two>
          <Two>Knowledge base</Two>
          <Two>Marketplace</Two>
          <Two>My Account</Two>
          <Link>Create support ticket</Link>
        </Navs>
        <Navs>
          <One>Expand & Learn</One>
          <Two>Patners</Two>
          <Two>Training & Certification</Two>
          <Two>Documentation</Two>
          <Two>Developer Resources</Two>
          <Two>Enterprise services</Two>
          <Link>View all resources</Link>
        </Navs>
        <Navs>
          <One>About Atlassian</One>
          <Two>Company</Two>
          <Two>Careers</Two>
          <Two>Events</Two>
          <Two>Blogs</Two>
          <Two>Investor Relations</Two>
          <Two>Trust & Security</Two>
          <Link>Contact us</Link>
        </Navs>
      </Wrapper>
      <Down>
        <Lang>
          <Icon1>
            <TfiWorld />
          </Icon1>
          <Text>English</Text>
          <Icon2>
            <MdArrowDropDown />
          </Icon2>
        </Lang>
        <Privacy>Privacy Policy</Privacy>
        <Terms>Terms</Terms>
        <Modern>Modern Slavery Act</Modern>
        <Impres>Impressum</Impres>
        <Copyright>
          Copyright <Icon3>{/* <BiCopyright /> */}</Icon3> 2022 Atlassian
        </Copyright>
        <Socialmedia>
          {/* <FB src={fb} /> */}
          {/* <TW src={tw} /> */}
          {/* <Linkd src={linkd} /> */}
          {/* <YT src={yt} /> */}
        </Socialmedia>
      </Down>
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  width: 100%;
  height: 50vh;
  background-color: #f4f5f7;
`;
const Logodiv = styled.div`
  height: 20%;
  width: 15%;
  /* background-color: blue; */
`;
const Navs = styled.div`
  height: 60%;
  width: 15%;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  /* text-align: left; */
  font-weight: 500;
`;
const Wrapper = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 70px;
`;
const Icon1 = styled.div`
  font-size: 30px;
  margin-right: 10px;
  color: grey;
`;
const Icon2 = styled.div`
  font-size: 30px;
  color: grey;
`;
const Down = styled.div`
  height: 12vh;
  width: 100%;
  border-top: 1px solid #bba7a7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f4f5f7;
`;
const Logo = styled.img``;
const One = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #4e4375;
  margin-bottom: 20px;

  :hover {
    text-decoration: underline;
  }
`;
const Two = styled.div`
  font-size: 18px;
  color: #58526e;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;
const Link = styled.div`
  margin-top: 30px;
  color: #0065ff;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;
const Lang = styled.div`
  height: 100%;
  /* width: 200px; */
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Privacy = styled.div``;
const Terms = styled.div``;
const Modern = styled.div``;
const Impres = styled.div``;
const Copyright = styled.div``;
const Socialmedia = styled.div``;

const Text = styled.div`
  font-size: 20px;
`;
const Icon3 = styled.img``;
const FB = styled.img``;
const TW = styled.img``;
const Linkd = styled.img``;
const YT = styled.img``;
// const Two = styled.div``
