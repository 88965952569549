import React from "react";
import styled from "styled-components";
import bgleft from "../Assets/hero-left.png";
import bgright from "../Assets/hero_right.jpg";

const Hero: React.FC = () => {
  return (
    <Container>
      <Wrapper>
        <Left>
          <Text>
            It’s possible <br /> <span>with teamwork</span>
          </Text>
        </Left>
        <Right>
          <Image src={bgright} />
        </Right>
      </Wrapper>
    </Container>
  );
};

export default Hero;

const Container = styled.div`
  /* height: 800px; */
  width: 100%;
  background-image: url(${bgleft});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  display: flex;
  overflow: hidden;
`;

const Left = styled.div`
  width: 45%;
  display: flex;
  align-items: center;
`;
const Right = styled.div`
  width: 45%;
`;
const Image = styled.img`
  height: 75%;
`;

const Text = styled.h5`
  font-size: 70px;
  /* margin-left: 50px; */
  color: #253858;
  /* font-weight: 40000px; */
  font-family: Charlie Text, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
  line-height: 100px;

  span {
    margin-left: 50px;
  }
`;

const Wrapper = styled.div`
  height: 100%;
  width: 75%;
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  margin-left: 95px;
`;
