import React, { useState } from "react";
import styled from "styled-components";
import logo from "../Assets/Atlassianlogo.webp";
import { HiOutlineChevronDown } from "react-icons/hi";
import { HiOutlineSearch } from "react-icons/hi";
import { IoMdContact } from "react-icons/io";
import { HiChevronDown } from "react-icons/hi";
import Teams from "./Teams";
import Product from "./Product";

const Header: React.FC = () => {
  const [show, setShow] = useState<Boolean>(false);
  const [show2, setShow2] = useState<Boolean>(false);
  const [show3, setShow3] = useState<Boolean>(false);

  const Toggle = () => {
    setShow(!show);
    setShow2(false);
    setShow3(false);
  };

  const Down = () => {
    setShow2(!show2);
    setShow(false);
    setShow3(false);
  };

  const Shows = () => {
    setShow3(!show3);
    setShow2(false);
    setShow(false);
  };
  return (
    <Container>
      <Wrapper>
        <FirstWrapper>
          <Logo src={logo} />
          <NavHold>
            <Nav onClick={Toggle}>
              Product{" "}
              <Icon>
                <HiOutlineChevronDown />
              </Icon>
            </Nav>
            <Nav onClick={Down}>
              For Teams{" "}
              <Icon>
                <HiOutlineChevronDown />
              </Icon>
            </Nav>
            <Nav onClick={Shows}>
              Support{" "}
              <Icon>
                <HiOutlineChevronDown />
              </Icon>
            </Nav>
          </NavHold>
        </FirstWrapper>
        <SecondWrapper>
          <Button>Try Now</Button>
          <Buy>Buy now</Buy>
          <Search>
            {" "}
            <HiOutlineSearch />
          </Search>
          <Line></Line>
          <Account>
            <Icon2>
              <IoMdContact />
            </Icon2>
            <h4>My account</h4>
            <Icon3>
              <HiChevronDown />
            </Icon3>
          </Account>
        </SecondWrapper>
      </Wrapper>
      {show ? <Product /> : null}
      {show2 ? (
        <Teams
          st="BY TEAM SIZE"
          up="Startups"
          gr="Great for startups, from imcubator to IPO"
          bu="Small business"
          gt="Get the right tools for growing business"
          ent="Enterprise"
          ln="Learn how we make big teams successful"
          iv="View all products"
          by="BY TEAM FUNCTION"
          sf="Software"
          pl="Plan, build, and ship quality product"
          ma="Marketing"
          br="Bring together a winning strategy"
          hr="HR"
          se="Streamline people management"
          le="Legal"
          op="Operate securely and reliably"
          ope="Operations"
          ru="Run your business efficiently"
          it="IT"
          pro="Provide great service and support"
          fi="Finance"
          sim="Simplify all finance processes"
          inn="Incident Response"
          ress="Respond, resolve and learn from incidents"
          mar="Marketplace"
          app="App that enhance Atlassian products"
          Dev="Developers"
          Doc="Docs and resources to build Atlassian Apps"
          tru="Trust and Security"
          com="Compliance, privacy, platform roadmap, and more"
          wrk="Work Life blog"
          sto="Stories on culture, tect, tips and teams"
          bse="dff"
        />
      ) : null}

      {show3 ? (
        <Teams
          st="RESOURCES"
          up="Documentation"
          gr="Guides to all of our products"
          bu="Atlassian Migration Program"
          gt="Tools and guidance for migration"
          ent="Cloud Roadmap"
          ln="Upcoming future releases"
          iv=""
          by="SUPPORT SERVICES"
          sf="Enterprice Services"
          pl="Personal support for larg teams"
          ma="Partner support"
          br="Trusted third-party consultant"
          hr="Atlassian Support"
          se="A resource hub for teams and admins"
          le="Purchasing and Licensing"
          op="FAQs about our policies"
          ope="About us"
          ru="Our mission and history"
          it="Careers"
          pro="Job openings, values, and more"
          fi="Atlassian University"
          sim="Trainning and certifications for all skill levels"
          inn="Atlassian Community"
          ress="A forum for connecting, sharing, and learning"
          mar="Marketplace"
          app="App that enhance Atlassian products"
          Dev="Developers"
          Doc="Docs and resources to build Atlassian Apps"
          tru="Trust and Security"
          com="Compliance, privacy, platform roadmap, and more"
          wrk="Work Life blog"
          sto="Stories on culture, tect, tips and teams"
          bse="dff"
        />
      ) : null}
    </Container>
  );
};

export default Header;

const Container = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  justify-content: center;
  /* background-color: blue; */
  /* margin-right: 100px; */
  /* margin-left: 100px; */
`;
const FirstWrapper = styled.div`
  /* background-color: white; */
  height: 100%;
  width: 45%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Logo = styled.img`
  height: 40px;
  margin-right: 10px;
  /* width: 50px; */
`;
const NavHold = styled.div`
  height: 50%;
  width: 50%;
  /* background-color: white; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-right: 10px; */
`;
const Nav = styled.div`
  /* height: 100%; */
  /* width: 15%; */
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;

  :hover {
    background-color: #dfe1e5;
    border-radius: 3px;
  }
`;
const SecondWrapper = styled.div`
  /* background-color: white; */
  width: 35%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-right: 30px; */
`;
const Icon = styled.div`
  margin-left: 10px;
  /* font-size: 50p; */
`;
const Button = styled.div`
  height: 40px;
  width: 80px;
  color: white;
  background-color: #0047b3;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Buy = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: #0047b3;
  cursor: pointer;
`;
const Search = styled.div`
  font-size: 30px;
  color: #ccc6c6;
`;
const Account = styled.div`
  display: flex;
  align-items: center;
  /* background-color: red; */
  height: 100%;
  width: 200px;
  justify-content: space-between;
  cursor: pointer;

  :hover {
    background-color: #ebedf0;
  }

  h4 {
    color: #0052cc;
  }
`;
const Icon2 = styled.div`
  font-size: 40px;
  color: #ccc6c6;
`;
const Icon3 = styled.div`
  font-size: 25px;
  color: #0052cc;
`;
const Line = styled.div`
  height: 30px;
  width: 1px;
  background-color: grey;
`;
const Wrapper = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
  /* background-color: red; */
`;
