import React from "react";
import styled from "styled-components";
import pic from "./Assets/Community.jpg";

const Essentials = () => {
  return (
    <Container>
      <Wrapper>
        <Left>
          <TextWrap>
            <Head>TEAM ESSENTIALS</Head>
            <Text>
              Explore resources to <br /> unleash the potential <br /> of your
              team
            </Text>
          </TextWrap>
        </Left>
        <Right>
          <Pic>
            <Image src={pic} />
          </Pic>
          <TextWrap1>
            <One>Atlassian Community</One>
            <Two>
              Connect globally and meet locally to get more out of our products.
            </Two>
            <Three>
              <Se>Search the forum</Se>
              <Jo>Join our community</Jo>
            </Three>
          </TextWrap1>
        </Right>
      </Wrapper>
    </Container>
  );
};

export default Essentials;

const Container = styled.div`
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
  margin-top: 100px;
`;
const Left = styled.div`
  height: 100%;
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  /* background-color: blue; */
  margin-right: 20px;
`;
const Right = styled.div`
  height: 100%;
  width: 70%;
  /* background-color: red; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;
const TextWrap = styled.div``;
const Image = styled.img`
  height: 100%;
  width: 100%;
`;
const Head = styled.div`
  font-weight: bold;
  font-size: 20px;
  color: #6d9ce2;
  margin-bottom: 20px;
`;
const Text = styled.div`
  font-size: 40px;
  font-weight: 600;
  color: #535353;
`;
const TextWrap1 = styled.div`
  width: 100%;
  height: 40%;
  display: flex;
  background-color: #ffebe5;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  justify-content: center;
`;
const Pic = styled.div`
  width: 100%;
  height: 60%;
`;
const One = styled.div`
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #455b7c;
`;
const Two = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: #455b7c;
`;
const Three = styled.div`
  display: flex;
  height: 40%;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  color: #296ed6;
`;
const Se = styled.div``;
const Jo = styled.div``;
const Wrapper = styled.div`
  height: 100%;
  width: 80%;
  display: flex;
  /* justify-content: space-between; */
`;
