import React from "react";
import styled from "styled-components";

interface Iprops {
  Image: string;
  bcol: string;
  bold: string;
  light: string;
  link: string;
}

const Eventcard: React.FC<Iprops> = ({ Image, bcol, bold, light, link }) => {
  return (
    <Container bcol={bcol}>
      <Favour>
        <img src={Image} alt="" />
      </Favour>
      <TextWrap>
        <Bold bold={bold}>{bold}</Bold>
        <Light light={light}>{light}</Light>
        <Link link={link}>{link}</Link>
      </TextWrap>
    </Container>
  );
};

export default Eventcard;

const Container = styled.div<{ bcol: string }>`
  height: 550px;
  width: 450px;
  border-radius: 10px;
  background-color: ${(props) => props.bcol};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  /* margin-left: 40px; */
`;
const Favour = styled.div`
  width: 100%;
  height: 60%;
  /* background-color: blue; */
  border-radius: 10px 10px 0px 0px;
  img {
    height: 100%;
    width: 100%;
  }
`;

const TextWrap = styled.div`
  width: 80%;
  height: 40%;
  /* background-color: white; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  justify-content: center;
  margin-left: 50px;
`;
const Bold = styled.div<{ bold: string }>`
  font-size: 30px;
  /* margin-top: 10p; */
  font-weight: 500;
  color: #4d3b3b;
  margin-bottom: 20px;
`;
const Light = styled.div<{ light: string }>`
  font-size: 20px;
  font-weight: 400;
  /* background-color: white; */
  line-height: 24px;
  margin-bottom: 20px;
  color: #413a3a;
`;
const Link = styled.div<{ link: string }>`
  color: #2d5faad8;
  font-size: 20px;
  font-weight: 500;
`;
// const Container = styled.div``
