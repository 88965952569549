import React from "react";
import styled from "styled-components";
import caright from "./Assets/careers image footer_left.png";
import caleft from "./Assets/careers image footer right.png";

const Careers = () => {
  return (
    <Container>
      <First>
        <Image1 src={caright} />
      </First>
      <Second>
        <TextWrap>
          <Car>Careers</Car>
          <Bold>We can't do it alone</Bold>
          <Light>
            We have an ambitious road ahead, and we’re looking for people to
            join our global team to help shape the future of Atlassian.
          </Light>
        </TextWrap>
        <Button>Join the Team</Button>
      </Second>
      <Third>
        <Image2 src={caleft} />
      </Third>
    </Container>
  );
};

export default Careers;

const Container = styled.div`
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
`;
const First = styled.div`
  width: 30%;
  height: 100%;
  /* background-image: url(${caright}); */
  /* background-color: red; */
`;
const Second = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /* background-color: BLUE; */
`;
const Third = styled.div`
  width: 30%;
  height: 100%;
  /* background-color: #427fb4; */
`;
const Image1 = styled.img`
  height: 100%;
`;
const Image2 = styled.img`
  height: 100%;
`;

const TextWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
`;
const Button = styled.div`
  height: 50px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0065ff;
  color: white;
  font-weight: bold;
  border-radius: 10px;
`;
const Car = styled.div`
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 70px;
  color: #0065ff;
  margin-top: 20px;
`;
const Bold = styled.div`
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
`;
const Light = styled.div`
  font-size: 25px;
`;
