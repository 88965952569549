import React from "react";
import Header from "./Components/Header/Header";
import Homescreen from "./Components/Homescreen";
import AllRoute from "./Components/Routes/AllRoute";

const App: React.FC = () => {
  return (
    <div>
      <Header />
      {/* <Homescreen /> */}
      <AllRoute />
    </div>
  );
};

export default App;
